import type { ButtonProps } from '@mui/material';
import { Button } from '@mui/material';

export function InquiryButton({ children, ...buttonProps }: ButtonProps): React.ReactElement {
  const defaultProps = {
    component: 'a',
    href: 'https://vpmlg6ys6hq.typeform.com/to/gQPQxC6t',
    target: '_blank',
  };

  return (
    <Button data-qa="inquiry-btn" {...buttonProps} {...defaultProps}>
      {children}
    </Button>
  );
}
