import type { PageProps } from 'gatsby';
import { graphql } from 'gatsby';
import type {
  MarkdownRemarkFrontmatterAdvantages,
  MarkdownRemarkFrontmatterCards,
  MarkdownRemarkFrontmatterPartners,
  UkPageQuery,
} from '../../../generated/types';
import { HomepageUkTemplate } from '../../components/templates/HomepageUkTemplate';
import { AdvantagesSection } from '../../modules/homepage/sections/advantages-section/AdvantagesSection';
import { ApiSection } from '../../modules/homepage/sections/api-section/ApiSection';
import { FeaturesSection } from '../../modules/homepage/sections/features-section/FeaturesSection';
import { HeroSection } from '../../modules/homepage/sections/hero-section/HeroSection';
import { PartnersSection } from '../../modules/homepage/sections/partners-section/PartnersSection';

type PpUkPage = PageProps<UkPageQuery>;

export default function UkPage({
  data: { standardHomepage, ukHomepage },
}: PpUkPage): React.ReactElement {
  return (
    <HomepageUkTemplate seo={ukHomepage?.frontmatter?.seo}>
      <HeroSection
        restricted
        disclaimer={ukHomepage?.frontmatter?.disclaimer}
        image={standardHomepage?.frontmatter?.heroImage}
        title={ukHomepage?.frontmatter?.heroTitle}
      />
      <PartnersSection
        partners={
          standardHomepage?.frontmatter?.partners as Array<MarkdownRemarkFrontmatterPartners>
        }
      />
      <FeaturesSection
        features={ukHomepage?.frontmatter?.cards as Array<MarkdownRemarkFrontmatterCards>}
        textWithIcon={ukHomepage?.frontmatter?.featuresTextWithIcon}
        title={ukHomepage?.frontmatter?.featuresTitle}
      />
      <ApiSection
        image1={standardHomepage?.frontmatter?.image1}
        image2={standardHomepage?.frontmatter?.image2}
        image3={standardHomepage?.frontmatter?.image3}
        image4={standardHomepage?.frontmatter?.image4}
        image5={standardHomepage?.frontmatter?.image5}
        title={ukHomepage?.frontmatter?.apiTitle}
      />
      <AdvantagesSection
        advantages={
          ukHomepage?.frontmatter?.advantages as Array<MarkdownRemarkFrontmatterAdvantages>
        }
        copy={ukHomepage?.frontmatter?.advantagesCopy}
        textWithIcon={ukHomepage?.frontmatter?.featuresTextWithIcon}
        title={ukHomepage?.frontmatter?.advantagesTitle}
      />
    </HomepageUkTemplate>
  );
}

export const query = graphql`
  query UkPage {
    standardHomepage: markdownRemark(
      frontmatter: { layout: { eq: "homepage" }, locale: { eq: "en" } }
    ) {
      frontmatter {
        heroImage {
          src
          alt
        }
        partners {
          id
          logo
          name
        }
        image1 {
          src
          alt
        }
        image2 {
          src
          alt
        }
        image3 {
          src
          alt
        }
        image4 {
          src
          alt
        }
        image5 {
          src
          alt
        }
      }
    }
    ukHomepage: markdownRemark(frontmatter: { layout: { eq: "UkHomepage" } }) {
      frontmatter {
        seo {
          title
          description
          image
        }
        heroTitle
        disclaimer
        featuresTitle
        featuresTextWithIcon
        cards {
          id
          title
          subtitle
        }
        apiTitle
        advantagesTitle
        advantagesCopy
        advantages {
          subtitle
          title
          image
        }
      }
    }
  }
`;
